import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout/Layout"

import Seo from "../../components/Seo"

export default function MentionsLegales() {
  const siteUrl = "yuli.stream"
  return (
    <Layout>
      <Seo title="Legal" index={false} follow={false} />
      <section className="container">
        <Link
          to="/legal/fr/"
          className="text-center"
          style={{ display: "block", marginTop: "2rem" }}
          title="View this page in french"
        >
          🇫🇷
        </Link>
        <h1 className="title text-center uppercase italic">Legal Notice</h1>
        <div className="shorten-text center-block justify">
          {/* Mentions légales
          -------------------------------------------------- */}
          <div id="introduction">
            {/* Introduction */}
            <p>
              By connecting to this site, you unreservedly accept these terms
              and conditions. Also, in accordance with article 6 of the Law No.
              2004-575 of June 21, 2004 for confidence in the economy digital,
              those responsible for this website {""}
              <i>{siteUrl}</i> are :
            </p>
          </div>
          <div id="editeur">
            {/* Éditeur du site */}
            <h2 className="title text-center uppercase italic">
              Site publisher
            </h2>
            <div>
              <ul>
                <li>
                  Publisher<p>Pierre Philippon</p>
                </li>
                <li>
                  Developer<p>Pierre Philippon</p>
                </li>
                <li>
                  Person responsible for publication<p>Pierre Philippon</p>
                </li>
                <li>
                  Legal status<p>Sole proprietorship</p>
                </li>
                <li>
                  Head office <p>136 rue de la Bruyère, 78300 POISSY</p>
                </li>
                <li>
                  SIRET<p>80754872200014</p>
                </li>
                <li>
                  Email
                  <p>
                    <a
                      href="mailto:pierre@urule.fr"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="legal-link_style"
                    >
                      pierre@urule.fr
                    </a>
                  </p>
                </li>
                <li>
                  Phone
                  <p>
                    <a
                      href="tel:33637102262"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="legal-link_style"
                    >
                      +33 6 37 10 22 62
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div id="hebergeur">
            {/* Hébergeur du site */}
            <h2 className="title text-center uppercase italic">Site host</h2>
            <div>
              <ul>
                <li>
                  Host<p>Netlify Inc.</p>
                </li>
                <li>
                  Head office
                  <p>
                    2325 3rd Street, Suite 215, San Francisco, California 94107,
                    États-Unis
                  </p>
                </li>
                <li>
                  Website
                  <p>
                    <a
                      href="wwww.netlify.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="legal-link_style"
                    >
                      www.netlify.com
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div id="conditions">
            {/* Conditions d'utilisation */}
            <h2 className="title text-center uppercase italic">Terms of use</h2>
            <p>
              The <i>{siteUrl}</i> site is available in different web languages.
              For a better comfort of use we recommend using modern browsers
              like Google Chrome, Firefox or Safari.
            </p>
            <p>
              The following legal notice was generated on the site{" "}
              <a
                title="générateur de mentions légales pour site internet gratuit"
                href="http://www.generateur-de-mentions-legales.com"
                rel="noopener noreferrer"
                target="_blank"
                className="legal-link_style"
              >
                Générateur de mentions légales
              </a>
              , provided by{" "}
              <a
                title="imprimerie paris, imprimeur paris"
                href="http://welye.com"
                rel="noopener noreferrer"
                target="_blank"
                className="legal-link_style"
              >
                Welye
              </a>
              .
            </p>
            <p>
              The <b>publisher</b> of the site is doing everything in its power
              to ensure reliable information and fast updates of his websites.
              However, errors or omissions can occur. The user must therefore
              check the accuracy of the information from the <b>publisher</b> of
              the site, and report any changes he finds useful. The{" "}
              <b>publisher</b> of the site is in no way responsible for the use
              made of this information, and any direct or indirect prejudice
              that may result.
            </p>
          </div>

          <div id="donnees">
            {/* Donnees */}
            <h2 className="title text-center uppercase italic">
              Personal data
            </h2>
            <p>
              The <i>{siteUrl}</i> site can automatically collect certain
              information about you during a simple navigation, in particular :
              information concerning the use of our site, such as the areas you
              visit and the services which you are accessing, your IP address,
              your web browser, access time. Such information is used
              exclusively for internal statistical purposes to improve the
              quality of the services offered to you. Databases are protected by
              the provisions of the law of July 1, 1998 transposing Directive
              96/9 of 11 March 1996 relating to legal protection of databases.
              You can find out more on the processing of data collected by this
              site in our{" "}
              <Link to="/privacy/en/" className="legal-link_style">
                Privacy notice.
              </Link>
            </p>
          </div>
          <div id="cookies">
            {/* Cookies */}
            <h2 className="title text-center uppercase italic">Cookies</h2>
            <p>
              The <i>{siteUrl}</i> site doesn't use cookies or any browser
              storage API.
            </p>
          </div>
          <div id="liens">
            {/* Liens hypertexte */}
            <h2 className="title text-center uppercase italic">Links</h2>
            <p>
              Websites may offer links to other sites or other resources
              available on the internet. The <b>publisher</b> of the site has no
              means of controlling the sites in connection with its websites.
              The <b>publisher</b> does not answer for the availability of such
              sites and external sources, nor does he guarantee it. He cannot be
              held responsible for damage of any kind resulting from the content
              of these sites or external sources, and in particular information,
              products or services they offer, or any use that may be made of
              these elements. The risks associated with this use are fully
              incumbent on the user, who must comply with their Terms of use.
            </p>
            <p>
              Website users, subscribers and visitors can not set up a hyperlink
              to this site without the express prior permission of the{" "}
              <b>publisher</b>.
            </p>
            <p>
              In the event that a user or visitor wishes to set up a hyperlink
              to one of the websites of the <b>publisher</b>, it will be up to
              him to send an email in order to formulate his request. The{" "}
              <b>publisher</b> reserves the right to accept or refuse a
              hyperlink without having to justify its decision.
            </p>
          </div>
          <div id="services">
            {/* Services fournis */}
            <h2 className="title text-center uppercase italic">
              Services provided
            </h2>
            <p>
              The <b>publisher</b> endeavors to provide on the site{" "}
              <i>{siteUrl}</i> {""}
              information as precise as possible. Information appearing on the
              site <i>{siteUrl}</i> are not exhaustive and photos are
              non-contractual. They are given subject to changes that have been
              made since they were put online. Through elsewhere, all the
              information indicated on the site {""}
              <i>{siteUrl}</i> are given for information only, and are subject
              to change or can change without notice.
            </p>
            <p>
              All the music services presented on this site are registered
              trademarks belonging to their respective owners.
            </p>{" "}
          </div>
          <div id="donnees">
            {/* Données */}
            <h2 className="title text-center uppercase italic">
              Contractual limitation on data
            </h2>
            <p>
              The information on this site is as precise as possible and the
              site is updated at different periods of the year, but may contain
              inaccuracies or omissions. If you notice a gap, error or what
              appears to be a malfunction, please report it by email at {""}
              <a
                href="mailto:pierre@urule.fr"
                rel="noopener noreferrer"
                className="legal-link_style"
              >
                pierre@urule.fr
              </a>
              , describing the problem as precisely as possible (problematic
              page, type of computer and browser used, …).
            </p>
            <p>
              Any downloaded content is done at the risk and peril of the user
              and under his sole responsibility. Accordingly, the{" "}
              <b>publisher</b> cannot be held responsible for any damage to the
              user's computer or to any loss of data due to downloading. The
              site user agrees to access the site using a recent hardware,
              virus-free and with an up-to-date last generation browser.
            </p>
          </div>
          <div id="propriete-intellectuelle">
            {/* Propriété intellectuelle */}
            <h2 className="title text-center uppercase italic">
              Intellectual property
            </h2>
            <p>
              All content on the <i>{siteUrl}</i> site, including, without
              limitation, graphics, images, texts, videos, animations, sounds,
              logos, gifs and icons as well as their setting form are the
              exclusive property of the company with the exception of brands,
              logos or content belonging to other companies, partners or
              authors.
            </p>
            <p>
              Any reproduction, distribution, modification, adaptation,
              retransmission or publication, even partial, of these different
              items is strictly prohibited without express written consent from
              the <b>publisher</b>. This representation or reproduction, by
              whatever process, constitutes a sanctioned infringement by
              articles L.335-2 and following of the Intellectual Property Code.
              Failure to comply with this prohibition constitutes a
              counterfeiting that may engage civil and criminal liability of the
              counterfeiter. In addition, the owners of the copied content could
              take legal action against you.
            </p>
          </div>

          <div id="declaration-cnil">
            {/* Déclaration à la CNIL */}
            <h2 className="title text-center uppercase italic">
              CNIL declaration
            </h2>
            <p>
              In accordance with the law 78-17 of January 6, 1978 (modified by
              the law 2004-801 of August 6, 2004 relating to the protection of
              persons physical with regard to the processing of personal data
              personnel) relating to IT, files and freedoms, this site has not
              been declared to the National Commission for Informatics and
              Liberties (
              <a
                href="http://www.cnil.fr/"
                target="_blank"
                rel="noopener noreferrer"
                className="legal-link_style"
              >
                www.cnil.fr
              </a>
              ).
            </p>
          </div>
          <div id="litiges">
            {/* Litiges */}
            <h2 className="title text-center uppercase italic">Dispute</h2>
            <p>
              These <i>{siteUrl}</i> site conditions are governed by French laws
              and any dispute or litigation which could arise from the
              interpretation or execution of these will be the exclusive
              jurisdiction of the courts whose depends on the registered office
              of the company. The reference language, for the settlement of
              possible disputes is French.
            </p>
          </div>
          {/* End of Mentions légales
          -------------------------------------------------- */}
          <Link to="/" className="accueil-link legal-link_style">
            Home
          </Link>
        </div>
      </section>
    </Layout>
  )
}
